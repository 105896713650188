import {  Typography } from "@material-tailwind/react";
import React from "react";
import JsonifyAPI from "../components/JsonifyAPI";

export default function Jsonify() {
  
  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex flex-row w-full place-content-between sm:mt-4">
        <Typography variant="h3">試卷數據轉化</Typography>
        
      </div>
      <Typography variant="paragraph" className='text-xs font-normal text-gray-500 mb-4'>
        This feature is used to create standardize data input for fine-tuning AI Model.
        </Typography>

      <JsonifyAPI></JsonifyAPI>
    </div>
  );
}
