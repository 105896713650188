import React from 'react';
import {

  Typography,

} from '@material-tailwind/react';


export default function Train() {

  return (
    <>
      <div className="flex flex-row w-full place-content-between sm:mt-4">
        <Typography variant="h3">
          Finetuning AI Model for EPG
        </Typography>
      </div>

     
   
    </>
  );
}

