import React, { useState } from "react";
import {
  Chip,
  Typography,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Textarea,
  Button,
} from "@material-tailwind/react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function Instructions() {
  const readingPaperSample = {
    reading_paper: {
      title: "English Reading Paper",
      grade_level: "Form 6",
      prompt: "Generate Questions set for this paper.",
      text_excerpt: {
        title: "Journey Through the Woods",
        author: "A. Writer",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit...",
      },
    },
  };
  const readingPaperPrompt =
    "Format the reading paper to readable clean output under this JSON sample! Strictly follow the sample and do not create additional json data field.";
  const data = [
    {
      label: "Jsonify Data",
      value: "a",
    },
    {
      label: "Train AI",
      value: "b",
    },
    {
      label: "Get Result",
      value: "c",
    },
  ];
  const [paper, setPaper] = useState("");
  const [testPaper, setTestPaper] = useState("");
  const [paperResult, setPaperResult] = useState({});
  const [question, setQuestion] = useState("");
  const [readingPrompt, setReadingPrompt] = useState("");

  function GetCleanReadingInput() {
    const a = JSON.stringify(readingPaperSample);
    const b = readingPaperPrompt + " Sample: " + a + " Reading Paper: " + paper;
    setReadingPrompt(b);
    return readingPrompt;
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row w-full place-content-between sm:mt-4">
        <Typography variant="h3">Instructions</Typography>
      </div>
      <div className="w-full">
        <Tabs value="html">
          <TabsHeader>
            {data.map(({ label, value }) => (
              <Tab key={value} value={value} className="text-sm text-normal">
                {label}
              </Tab>
            ))}
          </TabsHeader>
          <TabsBody>
            <TabPanel
              key="a"
              value="a"
              className="flex flex-col p-0 pt-4 gap-4"
            >
              <Typography
                variant="paragraph"
                className="flex flex-row gap-2 font-semibold"
              >
                Reading Paper to JSON
                <Chip value="Stable" variant="ghost" className="w-fit" />
              </Typography>
              <div className="flex flex-col  gap-2">
                <Typography variant="paragraph" className="font-normal">
                  Copy the reading paper into this textarea.
                </Typography>
                <Textarea
                  className="p-2 h-[10rem]"
                  label="Raw Paper Input"
                  onChange={(e) => {
                    setPaper(e.target.value);
                  }}
                />
                <Button
                  onClick={() => {
                    GetCleanReadingInput();
                    console.log(readingPaperPrompt);
                  }}
                >
                  Format
                </Button>
                <div className="flex flex-row justify-between gap-2 w-full h-auto">
                  <Typography
                    variant="paragraph"
                    className="flex flex-row text-xs w-9/12 overflow-scroll h-60 font-normal bg-white flex-nowrap border border-gray-200 rounded-md p-2"
                  >
                    {readingPrompt}
                  </Typography>

                  <div className="flex flex-col gap-2 w-3/12 h-60 border-2 rounded-md p-2 border-gray-200">
                    <Typography
                      variant="paragraph"
                      className="font-semibold text-md"
                    >
                      Functions
                    </Typography>
                    <CopyToClipboard
                      text={readingPrompt}
                      onCopy={() => {
                        alert("Copied successfully");
                      }}
                    >
                      <Button
                        className=" h-fit normal-case"
                        variant="gradient"
                        size="sm"
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                    <Button
                      className=" h-fit normal-case"
                      variant="outlined"
                      size="sm"
                    >
                      Send to OpenAI
                    </Button>
                  </div>
                </div>
              </div>
              <hr className="w-full my-2"></hr>

              <div className="flex flex-col  gap-2">
                <Typography
                  variant="paragraph"
                  className="flex flex-row gap-2 font-semibold"
                >
                  Test JSON Integrity
                  <Chip value="Stable" variant="ghost" className="w-fit" />
                </Typography>
                <Textarea
                  className="p-2 h-[10rem]"
                  label="Raw Paper Input"
                  onChange={(e) => {
                    setTestPaper(e.target.value);
                  }}
                />
                <Button
                  onClick={() => {
                    setPaperResult(JSON.parse(testPaper));
                    console.log(paperResult);
                  }}
                >
                  Test Integrity
                </Button>

                {Object.keys(paperResult).map(() => (
                  <div>
                    <h2>{paperResult.reading_paper.title}</h2>
                    <p>Grade Level: {paperResult.reading_paper.grade_level}</p>
                    <p>Prompt: {paperResult.reading_paper.prompt}</p>
                    <div>
                      <h3>
                        Title: {paperResult.reading_paper.text_excerpt.title}
                      </h3>
                      <p>
                        Author: {paperResult.reading_paper.text_excerpt.author}
                      </p>
                      <p>{paperResult.reading_paper.text_excerpt.content}</p>
                    </div>
                  </div>
                ))}
              </div>
            </TabPanel>
          </TabsBody>
        </Tabs>
      </div>
    </div>
  );
}
