import React from "react";
import { Button, Chip, Typography } from "@material-tailwind/react";
import FunctionCard from "../components/FunctionCard";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const LinkData = [
    {
      name: "Home",
      des: "Home page consists of different links and databases that you can access for training the AI Model",
      link: "/home",
    },
    {
      name: "Jsonify",
      des: "Jsonify Raw Reading Paper Passage & Question Answer Set for Data Input",
      link: "/jsonify",
    },
    {
      name: "Playground",
      des: "Use the AI Model to generate Question Answer Set according to the reading passage your provide",
      link: "/playground",
    },
  ];
  return (
    <>
      <div className="w-full h-full flex flex-col gap-2">
        {/**<div className="flex flex-row w-full mb-2 place-content-between sm:mt-4">
      <Typography variant="h3">主頁</Typography>
        </div>*/}
        <Chip
          value="How does this work?"
          className="p-4 bg-transparent border border-gray-400 text-black my-4"
        ></Chip>
        <Typography varient="paragraph" className="text-xs font-normal">
          This platform is used to facilitate the training of the AI Model in
          terms of the data quality and structure. First, you should navigate to{" "}
          <span>
            <Button
              className="p-0  bg-transparent text-black normal-case shadow-none"
              onClick={() => {
                navigate("/jsonify");
              }}
            >
              Jsonify
            </Button>
          </span>
          , which allows you to turn the raw passage or raw question answer set
          into standizard JSON format for AI Training Data.
        </Typography>
        <Typography varient="paragraph" className="text-xs font-normal">
          Next, combine the jsonified Data with the{" "}
          <span>
            <Button
              className="p-0 mb-[2px] bg-transparent text-black normal-case shadow-none"
              onClick={() => {
                navigate("/combination");
              }}
            >
              Combination Tool
            </Button>
          </span>{" "}
          (Database will be implemented soon) to create a formatted JSONL Data
          for OpenAI AI Model Training.
        </Typography>
        <Typography varient="paragraph" className="text-xs font-normal">
          Finally, upload the Taining Data to the OpenAI Server in{" "}
          <span>
            <Button
              className="p-0  mb-[2px] bg-transparent text-black normal-case shadow-none"
              onClick={() => {
                navigate("/train");
              }}
            >
              AI Training Portal
            </Button>
          </span>
          . When it is done, you can navigate to{" "}
          <span>
            <Button
              className="p-0 mb-[2px] bg-transparent text-black normal-case shadow-none"
              onClick={() => {
                navigate("/playground-finetuned");
              }}
            >
              Finetuned AI Playground
            </Button>
          </span>{" "}
          to test out the fine-tuned AI Model for Question Answer Set
          Generation.
        </Typography>
        <Chip
          value="Useful Navigation"
          className="p-4 bg-transparent border border-gray-400 text-black my-4"
        ></Chip>
        <div className="grid sm:grid-cols-2 gap-3 md:grid-cols-2 lg:md:grid-cols-3">
          {LinkData.map((item) => {
            return (
              <FunctionCard
                name={item.name}
                des={item.des}
                link={item.link}
              ></FunctionCard>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Home;
