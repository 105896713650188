import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import "./Tailwind.css";


import App from "./App";
import Home from "./pages/Home";
import Instructions from "./pages/Instructions/Instructions";
import Train from "./pages/Train";
import Playground from "./pages/Playground";
import Jsonify from "./pages/Jsonify";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ThemeProvider>
    
        <Router>
          <Routes>
          
          <Route path="/" element={<App><Home /></App>} />
          <Route path="*" element={<App><Home /></App>} />
          <Route path="/home" element={<App><Home /></App>} />
          <Route path="/jsonify" element={<App><Jsonify /></App>} />
          <Route path="/instructions" element={<App><Instructions /></App>} />
          <Route path="/train" element={<App><Train /></App>} />
          <Route path="/playground" element={<App><Playground /></App>} />
          </Routes>
        </Router>
    
    </ThemeProvider>
  </React.StrictMode>
);
