import React from 'react';
import {

  Typography,

} from '@material-tailwind/react';
import GenerateWithNormalGPT from '../components/GenerateWithNormalGPT';


export default function Playground() {

  return (
    <>
      <div className="flex flex-col w-full place-content-between mb-4 sm:mt-4">
        <Typography variant="h3">
        AI 試卷生成 GPT3.5
        </Typography>
        <Typography variant="paragraph" className='text-xs font-normal text-gray-500'>
        This is not a fine-tuned model. It is powered by the general GPT 3.5-Turbo.
        </Typography>
      </div>
      <GenerateWithNormalGPT></GenerateWithNormalGPT>

     
   
    </>
  );
}

