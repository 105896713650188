import React, { createContext, useContext, useState } from 'react';
import Navbar from './Navbar';
import { Typography } from '@material-tailwind/react';
import { KeyProvider } from './components/KeyContext';

function Dashboard({ children }) {
 

  // Return layout of left sidebar and right children content
  return (
    <KeyProvider>
    <div className="flex flex-col md:flex-row h-screen p-4 gap-4 sm:gap-0 ">
      <div className="flex flex-col gap-4 justify-between md:max-w-[250px]">
        <Navbar />
       
      </div>

      {/* Desktop */}
      <div className="hidden md:block flex-auto w-full rounded-2xl bg-gray-50 h-full overflow-scroll border border-gray-200">
        <div className="p-8 h-full">
          {children}
        </div>
      </div>

      {/* Mobile */}
      <div className="md:hidden w-full flex-auto p-2">
        {children}
      </div>

      <Typography
            variant="paragraph"
            
            color="blue-gray"
            className="sm:hidden self-center align-middle text-xs font-normal fixed bottom-0 p-2 bg-white w-full md:hidden text-center"
          >
            AI English Question Set Project Designed for LearnSmart Education.
          </Typography>

    </div>
    </KeyProvider>
  );
}

export default Dashboard;
