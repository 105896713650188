import { Button, Typography } from '@material-tailwind/react'
import { useNavigate } from 'react-router-dom';
import React from 'react'

export default function FunctionCard({name,des,img,link,color}) {
    const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-3 p-4 normal-case justify-between items-start w-full rounded-md border border-gray-400">
       <Typography variant='paragraph' className='font-semibold text-md text-gray-800'>
            {name}
        </Typography>
        <Typography variant='paragraph' className='font-normal text-xs text-gray-800'>
            {des}
        </Typography>
        <Button variant='outlined' className='py-2 pr-4 pl-4 w-full' onClick={()=>{navigate(link)}}>{"Go " + name}</Button>
    </div>
  )
}
