import React, { createContext, useContext, useState } from 'react';

const KeyContext = createContext();

export const KeyProvider = ({ children }) => {
  const [key, setKey] = useState('');

  return (
    <KeyContext.Provider value={{ key, setKey }}>
      {children}
    </KeyContext.Provider>
  );
};

export const useKey = () => {
  return useContext(KeyContext);
};