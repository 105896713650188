/* eslint-disable react/jsx-no-bind */
import React from "react";

import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Menu,
  MenuHandler,
  MenuList,
  Button,
  
} from "@material-tailwind/react";

import {
  HomeIcon,
  UserIcon,
  InboxIcon,
  DocumentChartBarIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline";

import { useNavigate, useLocation } from "react-router-dom";


function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const mobileNavButtonClass =
    "bg-white p-4 w-auto text-md border font-normal text-semibold normal-case -mr-3 hover:border-gray-50 rounded-full gap-2 flex flex-row align-middle items-center";

  const currentPath = location.pathname;

  

  // Logout handler and return to login page

  // Navbar layout
  return (
    <Card className="w-full gap-2 justify-between h-full" shadow={false}>
      <div>
        <div className="p-4 flex flex-row gap-3 w-full justify-between flex-wrap sm:pb-4">
          <div className="flex flex-row gap-3">
            <img
              src="/sitelogoblack500.png"
              className="align-middle object-fit w-5 h-5 self-center bg-transparent"
              alt="sitelogo"
            />
            <Typography
              variant="h5"
              color="blue-gray"
              className="self-center align-middle"
            >
              學博教育 AI
            </Typography>
          </div>

          {/* Mobile Nav Menu */}
          <div className=" flex flex-row gap-5 md:hidden">
          
            <Menu className="rounded md:hidden" placement="bottom-end">
              <MenuHandler>
                <Button
                  className="p-2 elf-end rounded-full border-none active:border-none focus-within:shadow-none focus:border-none shadow-none hover:shadow-none focus:shadow-none bg-transparent text-gray-700 "
                  ripple={false}
                  size="sm"
                >
                  <Bars3Icon className="h-5 w-5" />
                </Button>
              </MenuHandler>
              <MenuList className="md:hidden shadow-none mt-4 flex flex-col border-none gap-4 bg-transparent text-center text-semibold flex-wrap content-end justify-end justify-items-end align-bottom border-2 border-black">
                <Button
                  className={mobileNavButtonClass}
                  onClick={() => navigate("/home")}
                >
                  <HomeIcon className="h-5 w-5" />
                  主頁
                </Button>
               {/** <Button
                  className={mobileNavButtonClass}
                  onClick={() => navigate("/instructions")}
                >
                  <UserIcon className="h-5 w-5" />
                  使用手冊
                </Button>*/} 
                <Button
                  className={mobileNavButtonClass}
                  onClick={() => navigate("/jsonify")}
                >
                  <UserIcon className="h-5 w-5" />
                  試卷數據轉化 ✅
                </Button>
                <Button
                  className={mobileNavButtonClass}
                  onClick={() => navigate("/train")}
                >
                  <InboxIcon className="h-5 w-5" />
                  訓練 AI 模型 ❎
                </Button>
                <Button
                  className={mobileNavButtonClass}
                  onClick={() => navigate("/playground")}
                >
                  <DocumentChartBarIcon className="h-5 w-5" />
                  AI試卷生成 ✅
                </Button>
        
              </MenuList>
            </Menu>
            
          </div>
        </div>

        <hr className="md:hidden" />

        {/* Desktop Nav Menu */}
        <List className="visible sm:hidden">
          <ListItem
            onClick={() => navigate("/home")}
            className={`${
              currentPath === "/home"
                ? "bg-blue-gray-50 text-black"
                : "bg-white"
            }`}
          >
            <ListItemPrefix>
              <HomeIcon className="h-5 w-5" />
            </ListItemPrefix>
            <Typography className="font-semibold">主頁</Typography>
          </ListItem>

         {/** <ListItem
            onClick={() => navigate("/instructions")}
            className={`${
              currentPath === "/instructions"
                ? "bg-blue-gray-50 text-black"
                : "bg-white"
            }`}
          >
            <ListItemPrefix>
              <UserIcon className="h-5 w-5" />
            </ListItemPrefix>
            <Typography className="font-semibold">使用手冊</Typography>
          </ListItem>*/} 

          <ListItem
            onClick={() => navigate("/jsonify")}
            className={`${
              currentPath === "/jsonify"
                ? "bg-blue-gray-50 text-black"
                : "bg-white"
            }`}
          >
            <ListItemPrefix>
              <UserIcon className="h-5 w-5" />
            </ListItemPrefix>
            <Typography className="font-semibold">試卷數據轉化 ✅</Typography>
          </ListItem>

          <ListItem
            onClick={() => navigate("/train")}
            className={`${
              currentPath === "/train"
                ? "bg-blue-gray-50 text-black"
                : "bg-white"
            }`}
          >
            <ListItemPrefix>
              <InboxIcon className="h-5 w-5" />
            </ListItemPrefix>
            <Typography className="font-semibold">訓練 AI 模型 ❎</Typography>
          </ListItem>

          <ListItem
            onClick={() => navigate("/playground")}
            className={`${
              currentPath === "/playground"
                ? "bg-blue-gray-50 text-black"
                : "bg-white"
            }`}
          >
            <ListItemPrefix>
              <DocumentChartBarIcon className="h-5 w-5" />
            </ListItemPrefix>
            <Typography className="font-semibold">AI 試卷生成 ✅</Typography>
          </ListItem>
        </List>
      </div>
   
    </Card>
  );
}

export default Navbar;
